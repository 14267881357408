import React, { Component } from 'react'
import SelectUtente from './SelectUtente';
import { db } from "../services/firestore";
import {
    primoDelAnno,
    renderData
} from "../services/date";
import { Fragment } from 'react';
import { Table, Accordion, Card, Button } from 'react-bootstrap';
import { getViaggiUtenti } from '../services/utenti';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer
} from "recharts";



class ViaggiUtente extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            utente : '', 
            viaggiUtente : [], 
            bonusUtente:0,
            utenti:[],
            data:[]
     }
    }
    

    componentDidMount() {
        this.caricaViaggiUtente().then((dati)=>{
            this.setState({ data:dati });
        })

    }
    
    //funziona asincrona per caricamento complesso di viaggi per ogni utente!
    async caricaViaggiUtente(){
        let viaggiUtenti = await getViaggiUtenti(primoDelAnno());
        return viaggiUtenti;
    }
    

    handleChangeUtente(e){
        let utente = e.target.value;
        this.setState({ utente });

        //carico i viaggi per l'utente selezionato
        const viaggiRef = db
            .collection("/utenti/" + utente + "/viaggi")
            .where("data", ">", primoDelAnno())
            .orderBy("data", "desc");
            viaggiRef.onSnapshot((snap) => {
            let viaggiUtente = [];
            let bonusUtente = 0;
            snap.docs.forEach((viaggio) => {
                    viaggiUtente.push({ ...viaggio.data(), id: viaggio.id });
                    bonusUtente += viaggio.data().percorso.bonus; 
            });
            
            this.setState({ viaggiUtente, bonusUtente });
                console.log(this.state.viaggiUtente);
            });
    }

    render() { 
   
        return ( 
            <div>
                <div style={{ width: '100%', height: 600 }}>
                    <ResponsiveContainer>
                        <BarChart
                            width={900}
                            height={500}
                            data={this.state.data}
                            margin={{
                                top: 50,
                                right: 0,
                                left: 0,
                                bottom: 50,
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" interval={0} width={30} />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="viaggi" fill="blue" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>
                <br></br>
                <h1>Viaggi per utente</h1>
                <SelectUtente
                    onHandleChange={(e) => this.handleChangeUtente(e)}
                ></SelectUtente>
                <br></br>
                {this.state.viaggiUtente.length !== 0 ? (
                    <Fragment>
                        <span>Viaggi dall'inizio dell'anno : {this.state.viaggiUtente.length}</span><br></br>
                        <span>Bonus maturato dall'inizio dell'anno : {this.state.bonusUtente} €</span><br></br>
                        <br></br>
                        <Accordion>
                        <Card>
                            <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey="1">
                                Dettaglio viaggi
                            </Accordion.Toggle>
                            </Card.Header>
                            <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                    <tr>
                                        <th>#id</th>
                                        <th>Data</th>
                                        <th>Percorso</th>
                                        <th>Bonus</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.viaggiUtente.map((viaggio) => (
                                        <tr key={viaggio.id}>
                                        <td>{viaggio.id}</td>
                                        <td>{renderData(viaggio.data.seconds)}</td>
                                        <td>{viaggio.percorso.nome}</td>
                                        <td>{viaggio.percorso.bonus}</td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </Table>
                            </Card.Body>
                            </Accordion.Collapse>
                        </Card>
                        </Accordion>
                    </Fragment>
                ):(
                    <b>Nessun viaggio registrato!</b>
                )}
            </div>
         );
    }
}
 
export default ViaggiUtente;