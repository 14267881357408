import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { db } from "../services/firestore";

class SelectUtente extends Component {
  state = {
    utenti: [],
  };

  componentDidMount() {
    //carico utenti
    const utentiRef = db.collection("/utenti");
    let utenti = [];
    utentiRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let dato = doc.data();
          dato.id = doc.id;
          utenti.push(dato);
        });
        this.setState({ utenti: utenti });
        //console.log(utenti);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  handleChange(e) {
    this.props.onHandleChange(e);
  }

  render() {
    return (
      <Form.Control
        onChange={(e) => this.handleChange(e)}
        value={this.props.utente}
        name="utente"
        as="select"
      >
        <option value="-">-</option>
        {this.state.utenti.map((utente) => (
          <option value={utente.id} key={utente.id}>
            {utente.nomeUtente}
          </option>
        ))}
      </Form.Control>
    );
  }
}

export default SelectUtente;
