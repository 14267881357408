import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/App.css";
import BarraNavigazione from "./component/BarraNavigazione";
import Percorsi from "./component/Percorsi";
import Main from "./component/Main";
import Login from "./component/Login";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { auth } from "./services/firestore";
import Viaggi from "./component/Viaggi";
import { creaUtente } from "./services/utenti";
import Calendario from './component/Calendario';
import ViaggiUtente from './component/ViaggiUtente';
import AddToHomeScreen from '@ideasio/add-to-homescreen-react';


class App extends React.Component {
  state = {
    loading: true,
    user: {
      autenticato: false,
      uid: "",
      email: "",
      nomeUtente: "",
    },
  };

  componentDidMount() {
    auth.onAuthStateChanged((userAuth) => {
      if (userAuth) {
        creaUtente(userAuth);
        localStorage.removeItem("googlelogin");
        //console.log("autenticato : ", userAuth);
        this.setState({
          loading:false,
          user: {
            autenticato: true,
            uid: userAuth.uid,
            email: userAuth.email,
            nomeUtente: userAuth.displayName
              ? userAuth.displayName
              : userAuth.email,
          },
        });
      } else {
        this.setState({
          loading:false,
          user: {
            autenticato: false,
          },
        });
      }
    });
  }

  handleAddToHomescreenClick = () => {
    alert(`Grazie per aver aggiunto l'app!`);
  };

  render() {
    return !this.state.user.autenticato ? (
      <Login loading={this.state.loading} />
    ) : (
      <div className="App">
        <Router>
          <BarraNavigazione user={this.state.user}></BarraNavigazione>
          <Container>
            <Row>
              <Col>
                <Switch>
                <Route path="/calendario">
                    <Calendario user={this.state.user}></Calendario>
                  </Route>
                  <Route path="/percorsi">
                    <Percorsi user={this.state.user}></Percorsi>
                  </Route>                 
                  <Route path="/viaggi">
                    <Viaggi user={this.state.user}></Viaggi>
                  </Route>
                  <Route path="/viaggiutente">
                    <ViaggiUtente></ViaggiUtente>
                  </Route>
                  <Route path="/">
                    <Main user={this.state.user} />
                  </Route>
                </Switch>
              </Col>
            </Row>
          </Container>
        <AddToHomeScreen 
        appId='unitbike'
        startAutomatically={ true }
        />
        </Router>
      </div>
    );
  }
}

export default App;
