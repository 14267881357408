import { db } from "./firestore";

export function creaUtente(utente) {
  //console.log("creo utente", utente);
  const userRef = db.collection("utenti").doc(utente.uid);
  userRef.set({
    nomeUtente: utente.displayName ? utente.displayName : utente.email,
    email: utente.email,
    uid: utente.uid,
  });
}

export async function getDettagliUtente(userid) {
  const userRef = db.collection("utenti").doc(userid);
  let res = await userRef
    .get()
    .then(function (doc) {
      if (doc.exists) {
        return doc.data();
      } else {
        // doc.data() will be undefined in this case
        console.log("No such document!");
      }
    })
    .catch(function (error) {
      console.log("Error getting document:", error);
    });
  //console.log("dafn", res);
  return res;
}


export async function getViaggiUtenti(anno) {
  
  let res=[];
  let utenti=[];

  //estraggo utenti e aspetto che siano tutti
  await db
    .collection("/utenti/")
    .get()
    .then(async (docs)=>{

      for await(let utente of docs.docs){
          utenti.push(utente.data());
      }

    })

  for await(let utente of utenti){
    await db
    .collection("/utenti/" + utente.uid + "/viaggi")
    .where("data", ">", anno)
    .orderBy("data", "desc")
    .get()
    .then( (docs) => {
      let ut={};
      let numeroviaggi = docs.size;
      let idutente = utente.nomeUtente
      ut.name = idutente;
      ut.viaggi = numeroviaggi;
      res.push(ut);
    });
  }

  return res;
}
