import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import AggiungiViaggioOggi from "./AggiungiViaggioOggi";
import SommaViaggiMese from "./SommaViaggiMese";
import {
  primoDelMese,
  primoDelMeseScorso,
  getNomeMese,
  primoDelAnno,
  primoDelAnnoScorso,
  ora,
} from "../services/date";

class Main extends Component {
  state = {
    datas1: primoDelMese(),
    datae1: ora(),
    mese1: getNomeMese(new Date().getMonth()),
    datas2: primoDelMeseScorso(),
    datae2: primoDelMese(),
    mese2: getNomeMese(new Date().getMonth() - 1),
    datas3: primoDelAnno(),
    datae3: ora(),
    mese3: new Date().getFullYear(),
    //mese4: '2020',
    datas4: primoDelAnnoScorso(),
    datae4: primoDelAnno(),
    annoprec : new Date().getFullYear()-1
  };

  render() {
    return (
      <Container fluid>
        <Row className="justify-content-md-center mt-5">
          <Col xs lg="2"></Col>
          <Col md="auto">
            <AggiungiViaggioOggi
              onViaggioAggiunto={() => this.viaggioAggiunto()}
              user={this.props.user}
            ></AggiungiViaggioOggi>
          </Col>
          <Col xs lg="2"></Col>
        </Row>
        <Row className="justify-content-md-center mt-5">
          <Col xs={12} md={6} lg={4}>
            <SommaViaggiMese
              articolo="di"
              mese={this.state.mese1}
              start={this.state.datas1}
              end={this.state.datae1}
              user={this.props.user}
            ></SommaViaggiMese>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <SommaViaggiMese
              articolo="di"
              mese={this.state.mese2}
              start={this.state.datas2}
              end={this.state.datae2}
              user={this.props.user}
            ></SommaViaggiMese>
          </Col>
          <Col xs={12} md={6} lg={4}>
            <SommaViaggiMese
              articolo="del"
              mese={this.state.mese3}
              start={this.state.datas3}
              end={this.state.datae3}
              user={this.props.user}
            ></SommaViaggiMese>
          </Col>
        </Row>
        <Row className="justify-content-md-center mt-5">
          <Col xs={12} md={12} lg={12}>
            <h1>Viaggi del {this.state.annoprec}</h1>

            <SommaViaggiMese
              articolo="del"
              mese={this.state.annoprec}
              start={this.state.datas4}
              end={this.state.datae4}
              user={this.props.user}
            ></SommaViaggiMese>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Main;
