import React, { Component } from "react";
import { Form, Button, Card, Container } from "react-bootstrap";
import { db } from "../services/firestore";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import it from "date-fns/locale/it";
import SelectPercorso from "./SelectPercorso";
import SelectUtente from "./SelectUtente";
registerLocale("it", it);

//Todo : rifare tutta l'aggiunta dei viaggi
class AggiungiViaggio extends Component {
  constructor(props) {
    super(props);
    this.state = {
      utente: this.props.user.uid,
      viaggio: {
        data: "",
        percorso: {},
      },
    };
  }

  handleChangeUtente(e) {
    let utente = e.target.value;
    this.setState({ utente });
  }

  handleChangePercorso(e, percorso) {
    let viaggio = { ...this.state.viaggio };
    viaggio.percorso = percorso;
    this.setState({ viaggio });
  }

  handleChange(e) {
    let viaggio = { ...this.state.viaggio };
    viaggio[e.target.name] = e.target.value;
    this.setState({ viaggio });
  }

  handleChangeDate = (date) => {
    let viaggio = { ...this.state.viaggio };
    viaggio.data = date;
    console.log(date);
    this.setState({ viaggio });
  };

  aggiungiViaggio(e) {
    e.preventDefault();
    console.log("aggiungo viaggio", this.state);
    db.collection("/utenti/")
      .doc(this.state.utente)
      .collection("/viaggi")
      .doc()
      .set(this.state.viaggio)
      .then(function () {
        console.log("Viaggio aggiunto");
      });
  }

  componentDidMount() {}

  render() {
    return (
      <Card>
        <Card.Body>
          <Container>
            <Form onSubmit={(e) => this.aggiungiViaggio(e)}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>Utente</Form.Label>
                <SelectUtente
                  utente={this.props.utente}
                  onHandleChange={(e) => this.handleChangeUtente(e)}
                ></SelectUtente>
                <Form.Label>Percorso</Form.Label>
                <SelectPercorso
                  onHandleChange={(e, percorso) =>
                    this.handleChangePercorso(e, percorso)
                  }
                ></SelectPercorso>
              </Form.Group>
              <Form.Group>
                <Form.Label>Data</Form.Label>
                <br></br>
                <DatePicker
                  placeholderText="Inserisci una data"
                  locale="it"
                  name="data"
                  dateFormat="dd/MM/yyyy"
                  selected={this.state.viaggio.data}
                  onChange={(date) => this.handleChangeDate(date)}
                />
              </Form.Group>
              <Form.Group controlId="formBasicPassword"></Form.Group>
              <Button variant="primary" type="submit">
                Aggiungi
              </Button>
            </Form>
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

export default AggiungiViaggio;
