import React, { Component } from "react";
import { Form } from "react-bootstrap";
import { db } from "../services/firestore";

class SelectPercorso extends Component {
  state = { percorsi: [] };

  handleChange(e) {
    if (e.target.value !== "") {
      const percorso = JSON.parse(e.target.value);
      this.props.onHandleChange(e, percorso);
    }
  }

  componentDidMount() {
    //carico i percorsi
    const percorsiRef = db.collection("/percorsi");
    let percorsi = [];
    percorsiRef
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach(function (doc) {
          let dato = doc.data();
          dato.id = doc.id;
          percorsi.push(dato);
        });
        this.setState({ percorsi: percorsi });
        //console.log(percorsi);
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  render() {
    return (
      <Form.Control
        onChange={(e) => this.handleChange(e)}
        name="percorso"
        as="select"
      >
        <option value="-">-</option>
        {this.state.percorsi.map((percorso) => (
          <option value={JSON.stringify(percorso)} key={percorso.id}>
            {percorso.nome}
          </option>
        ))}
      </Form.Control>
    );
  }
}

export default SelectPercorso;
