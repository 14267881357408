import React, { Fragment } from "react";
import { db } from "../services/firestore";
import { Button, Card, Container, Row, Col } from "react-bootstrap";
import { getDettagliUtente } from "../services/utenti";

class ListaPercorsi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      percorsi: [],
    };
  }

  componentDidMount() {
    //carico i percorsi
    const percorsiRef = db.collection("/percorsi");
    percorsiRef.onSnapshot((snap) => {
      let percorsi = [];
      snap.docs.forEach((percorso) => {
        percorsi.push({ ...percorso.data(), id: percorso.id });
      });

      this.setState({ percorsi });
      this.fixUsername();
    });
  }

  eliminaPercorso(id) {
    this.props.onEliminaPercorso(id);
  }

  modificaPercorso(id) {
    this.props.onModificaPercorso(id);
  }

  async fixUsername() {
    let percorsi = [...this.state.percorsi];
    for (let percorso of percorsi) {
      if (percorso.utente) {
        percorso.nomeUtente = await getDettagliUtente(percorso.utente);
        //TODO bruttissimo da cambiare
        percorso.nomeUtente = percorso.nomeUtente.nomeUtente;
      }
    }
    this.setState({ percorsi });
  }

  renderPercorsi() {
    const { percorsi } = this.state;
    let li = [];

    percorsi.forEach((percorso) => {
      li.push(
        <Col
          style={{ minWidth: "33%" }}
          xs={{ span: 12 }}
          md={{ span: 4 }}
          key={percorso.id}
        >
          <Card
            id={percorso.id}
            key={percorso.id}
            style={{ marginTop: "20px" }}
          >
            <Card.Body>
              <Card.Text>
                <b>{percorso.nome}</b>
                <br />
                {percorso.lunghezza / 1000} Km
                <br />
                <b>utente : </b>
                {percorso.nomeUtente}
                <br></br>
                <b>bonus : </b>
                {percorso.bonus}€
              </Card.Text>
              <Button
                variant="danger"
                onClick={() => this.eliminaPercorso(percorso.id)}
              >
                Elimina
              </Button>
              &nbsp;
              <Button onClick={() => this.modificaPercorso(percorso.id)}>
                Modifica
              </Button>
            </Card.Body>
          </Card>
        </Col>
      );
    });

    return li;
  }

  render() {
    return (
      <Fragment>
        <br></br>
        <h3 style={{ textAlign: "center" }}>I percorsi:</h3>
        <Container fluid>
          <Row>{this.renderPercorsi()}</Row>
        </Container>
      </Fragment>
    );
  }
}

export default ListaPercorsi;
