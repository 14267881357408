import React, { Component } from "react";
import { Button, Container, Alert } from "react-bootstrap";
import { db } from "../services/firestore";
import { oggi } from "../services/date";

class AggiungiViaggioOggi extends Component {
  state = {
    percorsoPresente: true,
    show: false,
    percorso: {},
    utente: this.props.user,
    data: "",
  };

  componentDidMount() {
    //imposto data
    let data = oggi();
    this.setState({ data });

    //estraggo percorso SOLO IL PRIMO RISULTATO!
    db.collection("percorsi")
      .where("utente", "==", this.props.user.uid)
      .limit(1)
      .get()
      .then((res) => {
        if (!res.empty) {
          this.setState({
            percorso: { ...res.docs[0].data(), id: res.docs[0].id },
            percorsoPresente: true,
          });
        } else {
          this.setState({ percorsoPresente: false });
        }
      });
  }

  aggiungiViaggio() {
    let viaggio = {
      data: this.state.data,
      percorso: this.state.percorso,
    };
    db.collection("/utenti/")
      .doc(this.state.utente.uid)
      .collection("/viaggi")
      .doc()
      .set(viaggio)
      .then(() => {
        console.log("Viaggio aggiunto");
        this.setState({ show: true });
      });
  }

  setShow() {
    this.setState({ show: false });
  }

  render() {
    return (
      <Container style={{ textAlign: "center" }}>
        <p>1 Viaggio : casa-lavoro-casa</p>
        {this.state.percorsoPresente ? (
          <Button onClick={() => this.aggiungiViaggio()}>
            Aggiungi Viaggio!
          </Button>
        ) : (
          <Alert variant="danger">
            Nessun percorso configurato per l'utente
          </Alert>
        )}
        {this.state.show ? (
          <Alert
            style={{ marginTop: "20px" }}
            variant="success"
            onClose={() => this.setShow()}
            dismissible
          >
            <Alert.Heading>Bravo {this.props.user.nomeUtente}</Alert.Heading>
            <p>Continua a pedalare e la pancia sparirà!</p>
          </Alert>
        ) : null}
      </Container>
    );
  }
}

export default AggiungiViaggioOggi;
