import React from "react";
import { Form, Button, Card, Container, Row, Col } from "react-bootstrap";
import SelectUtente from "./SelectUtente";

class AggiungiPercorso extends React.Component {
  state = {
    bonus: 0,
  };

  handleChange(e) {
    this.props.onHandleChange(e);
  }
  handleSubmit(e) {
    this.props.onHandleSubmit(e, this.props.id);
  }
  resetForm(e) {
    this.props.onResetForm(e);
  }

  calcolaBonus() {
    const { lunghezza, dislivello } = this.props.percorso;
    if (lunghezza && dislivello) {
      let bonus = Math.round(
        (parseInt(lunghezza) + parseInt(dislivello) * 10) / 1000 / 2
      );
      console.log("bonus", bonus);
      this.props.percorso.bonus = bonus;
      //if (lunghezza && dislivello) {
      return bonus;
      //}
    }
  }

  render() {
    return (
      <Card>
        <Card.Body>
          <Container>
            <Row>
              <Col md={{ span: 6, order: 1 }} xs={{ span: 12, order: 2 }}>
                <h3>Aggiungi o modifica un percorso</h3>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Label>Nome Percorso</Form.Label>
                    <Form.Control
                      name="nome"
                      type="text"
                      value={this.props.percorso.nome}
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Inserisci il nome "
                    />
                    <Form.Label>Utente</Form.Label>
                    <SelectUtente
                      utente={this.props.percorso.utente}
                      onHandleChange={(e) => this.handleChange(e)}
                    ></SelectUtente>
                    <Form.Label>Lunghezza(metri)</Form.Label>
                    <Form.Control
                      name="lunghezza"
                      type="number"
                      value={this.props.percorso.lunghezza}
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Lunghezza"
                    />
                    <Form.Label>Dislivello(metri)</Form.Label>
                    <Form.Control
                      name="dislivello"
                      type="number"
                      value={this.props.percorso.dislivello}
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Dislivello"
                    />
                    <Form.Label>Bonus(€)</Form.Label>
                    <Form.Control
                      readOnly
                      name="bonus"
                      type="number"
                      value={this.calcolaBonus()}
                      onChange={(e) => this.handleChange(e)}
                      placeholder="Bonus"
                    />
                  </Form.Group>
                  <Button variant="primary" type="submit">
                    Salva
                  </Button>
                  &nbsp;
                  <Button
                    onClick={(e) => this.resetForm(e)}
                    variant="primary"
                    type="button"
                  >
                    Reset
                  </Button>
                </Form>
              </Col>
              <Col md={1} xs={0}></Col>
              <Col xs={{ order: 1 }} md={{ order: 3 }}>
                <h3>Aiuto:</h3>
                <p>
                  Inserisci un percorso casa-lavoro. Il bonus viene calcolato
                  automaticamente con questa formula: ((lunghezza) +
                  (dislivello) * 10) / 1000 / 2
                </p>
                <p>
                  Inserire il dislivello positivo, sia che venga fatto
                  all'andata che al ritorno.
                </p>
              </Col>
            </Row>
          </Container>
        </Card.Body>
      </Card>
    );
  }
}

export default AggiungiPercorso;
