import React, { Component } from "react";
import { Card, Badge } from "react-bootstrap";
import { db } from "../services/firestore";
import CountTo from "react-count-to";

class SommaViaggiMese extends Component {
  state = {
    conteggio: 0,
    sommaBonus: 0,
    sommaKm: 0,
    dsl:0
  };

  componentDidMount() {
    const { start, end } = this.props;

    this.setState({
      dataInizio: start.toDate().toLocaleDateString(),
      dataFine: end.toDate().toLocaleDateString(),
    });

    let sommaBonus = 0;
    let sommaKm = 0;
    let conteggio = 0;
    let dsl=0;

    db.collection("utenti")
      .doc(this.props.user.uid)
      .collection("viaggi")
      .where("data", ">", start)
      .where("data", "<=", end)
      .onSnapshot((res) => {
        conteggio = res.docs.length;
        res.docs.forEach((viaggio) => {
          //console.log("svm", viaggio);
          //console.log("viaggi", viaggio.data().percorso.bonus);
          sommaBonus += parseInt(viaggio.data().percorso.bonus);
          sommaKm += parseInt(viaggio.data().percorso.lunghezza);
          dsl += parseInt(viaggio.data().percorso.dislivello);
        });
        this.setState({ conteggio, sommaBonus, sommaKm, dsl });
        sommaBonus = 0;
        conteggio = 0;
        sommaKm = 0;
        dsl = 0;
      });
  }

  render() {
    //console.log("render svms");
    return (
      <Card style={{ marginTop: "20px" }}>
        <Card.Body>
          <Card.Title>Viaggi {this.props.articolo} {this.props.mese}</Card.Title>
          <Card.Text>
            Dal {this.state.dataInizio} al {this.state.dataFine}
            <br></br><br></br>
            <b>Viaggi Effettuati : </b>
            <Badge variant="secondary" style={{fontSize:'110%'}}>
              <CountTo to={this.state.conteggio} speed={300} />
            </Badge>
            <br></br><br></br>
            <b>Bonus Maturato : </b>
            <Badge variant="secondary" style={{fontSize:'110%'}}>
              <CountTo to={this.state.sommaBonus} speed={450} /> €
            </Badge>
            <br></br><br></br>
            <b>Km pedalati: </b>
            <Badge variant="secondary" style={{fontSize:'110%'}}>
              <CountTo to={this.state.sommaKm/1000} speed={650} /> Km
            </Badge>
            <br></br><br></br>
            <b>DSL +: </b>
            <Badge variant="secondary" style={{fontSize:'110%'}}>
              <CountTo to={this.state.dsl} speed={550} /> m
            </Badge>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default SommaViaggiMese;
