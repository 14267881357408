import React from "react";
import { Fragment } from "react";
import { Navbar, Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import { auth } from "../services/firestore";
//import { logo } from "./../img/bike.png";

class BarraNavigazione extends React.Component {
  state = {};

  handleLogout() {
    console.log("logout");
    auth
      .signOut()
      .then(function () {
        //console.log(error);
      })
      .catch(function (error) {
        // An error happened.
        console.log(error);
      });
  }

  render() {
    return (
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Navbar.Brand to="/">UnitBike</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link as={Link} to="/">
              Home
            </Nav.Link>
            <Nav.Link as={Link} to="/calendario">
              Calendario
            </Nav.Link>
            <Nav.Link as={Link} to="/viaggiutente">
              Viaggi/Utente
            </Nav.Link>
            {this.props.user.email === "andrea.caspani@unitbit.com" ? (
              <Fragment>
                  <Nav.Link as={Link} to="/percorsi">
                    Percorsi
                  </Nav.Link>
                  <Nav.Link as={Link} to="/viaggi">
                    Viaggi
                  </Nav.Link>
              </Fragment>
            ):('')}
          </Nav>
          <Navbar.Collapse className="justify-content-end">
            <Navbar.Text>
              Utente: <a href="#login">{this.props.user.nomeUtente}</a>
            </Navbar.Text>
            <Nav.Link as={Link} to="#" onClick={() => this.handleLogout()}>
              Logout
            </Nav.Link>
          </Navbar.Collapse>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default BarraNavigazione;
