import React, { Component } from 'react'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import moment from 'moment'
import { db } from "../services/firestore";
import 'react-big-calendar/lib/css/react-big-calendar.css'
import {
    dataDaSecondi,
    primoDelAnno
  } from "../services/date";
import "moment/locale/it";
//import { Table, Button } from "react-bootstrap";

const localizer = momentLocalizer(moment);
const messages = {
    allDay: "Tutto il giorno",
    previous: "<",
    next: ">",
    today: "Oggi",
    month: "Mese",
    week: "Settimana",
    day: "Giorno",
    agenda: "Agenda",
    date: "Data",
    time: "Ora",
    event: "Evento",
    showMore: (total) => `+ Mostra (${total})`,
};

class Calendario extends Component {
    state = {
        dateViaggi : [],
    };


    componentDidMount() {
            
            const viaggiRef = db
            .collection("/utenti/" + this.props.user.uid + "/viaggi")
            .where("data", ">", primoDelAnno())
            .orderBy("data", "desc");
    
            viaggiRef.onSnapshot((snap) => {
                let dateViaggi = [];
                snap.docs.forEach((viaggio) => {
                    let dataok = (dataDaSecondi(viaggio.data().data.seconds))
                    dateViaggi.push({ 
                            id: viaggio.id,
                            title:viaggio.data().percorso.nome,
                            start:dataok,
                            end:dataok  
                    });
                });
                this.setState({ dateViaggi });
                //console.log(dateViaggi);
                console.log(this.state.dateViaggi);
            });

    }

    cambioMese(data){

        //primo del mese
        let dataInizio = new Date(data.getFullYear(), data.getMonth()-1, 1);
        
        const viaggiRef2 = db
        .collection("/utenti/" + this.props.user.uid + "/viaggi")
        .where("data", ">", dataInizio)
        .orderBy("data", "desc");
        viaggiRef2.get().then((doc) => {
            let dateViaggi = [];
            doc.forEach((doc) => {
                let dataok = (dataDaSecondi(doc.data().data.seconds))
                dateViaggi.push({ 
                    id: doc.id,
                    title:doc.data().percorso.nome,
                    start:dataok,
                    end:dataok  
                });
                // doc.data() is never undefined for query doc snapshots
            });
            this.setState({ dateViaggi });
        }).catch((error) => {
            console.log("Error getting document:", error);
        });
    }
    

    render(){
        return(
            <div>
                <br></br>
                <h1>I miei viaggi per mese</h1>
                <Calendar
                    messages={messages}
                    localizer={localizer}
                    events={this.state.dateViaggi}
                    startAccessor="start"
                    endAccessor="end"
                    onNavigate={(data)=>this.cambioMese(data)}
                    style={{ height: 500 }}
                    />
            </div>
        )
    }
}

export default Calendario;