import React, { Component } from "react";
import ListaViaggi from "./ListaViaggi";
import { Container } from "react-bootstrap";
import AggiungiViaggio from "./AggiungiViaggio";

class Viaggi extends Component {
  state = {};

  render() {
    return (
      <Container>
        <h3>Aggiungi un viaggio</h3>
        <AggiungiViaggio user={this.props.user}></AggiungiViaggio>
        <h3>I tuoi ultimi viaggi (anno in corso)</h3>
        <ListaViaggi user={this.props.user}></ListaViaggi>
      </Container>
    );
  }
}

export default Viaggi;
