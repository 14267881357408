import React, { Component } from "react";
import { Table, Button } from "react-bootstrap";
import { db } from "../services/firestore";
import {
  primoDelAnno,
  renderData
} from "../services/date";


class ListaViaggi extends Component {
  state = { viaggi: [] };

  componentDidMount() {
    //carico i percorsi
    const viaggiRef = db
      .collection("/utenti/" + this.props.user.uid + "/viaggi")
      .where("data", ">", primoDelAnno())
      .orderBy("data", "desc");
    viaggiRef.onSnapshot((snap) => {
      let viaggi = [];
      snap.docs.forEach((viaggio) => {
        viaggi.push({ ...viaggio.data(), id: viaggio.id });
      });
      this.setState({ viaggi: viaggi });
      console.log(this.state);
    });
  }

  eliminaViaggio(id) {
    console.log("elimino: ", id);

    db.collection("/utenti/" + this.props.user.uid + "/viaggi")
      .doc(id)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }

  render() {
    return (
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#id</th>
            <th>Data</th>
            <th>Percorso</th>
            <th>Bonus</th>
            <th>Azioni</th>
          </tr>
        </thead>
        <tbody>
          {this.state.viaggi.map((viaggio) => (
            <tr key={viaggio.id}>
              <td>{viaggio.id}</td>
              <td>{renderData(viaggio.data.seconds)}</td>
              <td>{viaggio.percorso.nome}</td>
              <td>{viaggio.percorso.bonus}</td>
              <td>
                <Button onClick={() => this.eliminaViaggio(viaggio.id)}>
                  Elimina
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }
}

export default ListaViaggi;
