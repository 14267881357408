import { fb } from "./firestore";

const monthNames = [
  "Gennaio",
  "Febbraio",
  "Marzo",
  "Aprile",
  "Maggio",
  "Giugno",
  "Luglio",
  "Agosto",
  "Settembre",
  "Ottobre",
  "Novembre",
  "Dicembre",
];

export function oggi() {
  let date = new Date();
  return fb.firestore.Timestamp.fromDate(new Date(date.setHours(0)));
}

export function ora() {
  return fb.firestore.Timestamp.fromDate(new Date());
}

export function primoDelMese() {
  let date = new Date();
  let primo = new Date(date.getFullYear(), date.getMonth(), 1);
  return fb.firestore.Timestamp.fromDate(primo);
}

export function primoDelMeseScorso() {
  let date = new Date();
  let primo = new Date(date.getFullYear(), date.getMonth() - 1, 1);
  return fb.firestore.Timestamp.fromDate(primo);
}

export function primoDelAnno() {
  let date = new Date();
  let primo = new Date(date.getFullYear(), 0, 1);
  return fb.firestore.Timestamp.fromDate(primo);
}

export function primoDelAnnoScorso() {
  let date = new Date();
  let primo = new Date(date.getFullYear()-1, 0, 1);
  return fb.firestore.Timestamp.fromDate(primo);
}

//Todo : cosa succede a gennaio!?!?!?
export function getNomeMese(mese) {
  return monthNames[mese];
}

export function dataDaSecondi(seconds) {
  let data = new Date(seconds * 1000);
  return data;
}

export function renderData(seconds) {
  let data = new Date(seconds * 1000);
  return data.toLocaleDateString();
}