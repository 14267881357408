import React, { Component } from "react";
import AggiungiPercorso from "./AggiungiPercorso";
import ListaPercorsi from "./ListaPercorsi";
import { db } from "./../services/firestore";
import { Container } from "react-bootstrap";

class Percorsi extends Component {
  state = {
    id: "",
    percorso: {
      nome: "",
      lunghezza: "",
      utente: "",
      dislivello: "",
      bonus: "",
    },
  };

  handleChange(event) {
    let percorso = { ...this.state.percorso };
    percorso[event.target.name] = event.target.value;
    this.setState({ percorso });
  }

  handleSubmit(event, id) {
    if (id) {
      console.log("modifica", id);
      event.preventDefault();
      db.collection("/percorsi/")
        .doc(id)
        .set(this.state.percorso)
        .then(function () {
          console.log("Percorso salvato");
        });
    } else {
      console.log("nuovo", this.state.percorso);
      event.preventDefault();
      db.collection("/percorsi/")
        .doc()
        .set(this.state.percorso)
        .then(function () {
          console.log("Percorso aggiunto");
        });
    }
  }

  modificaPercorso(id) {
    const ref = db.collection("/percorsi/").doc(id);
    ref.get().then((doc) => {
      this.setState({ id: doc.id, percorso: doc.data() });
    });
    this.resetForm();
  }

  eliminaPercorso(id) {
    this.resetForm();
    console.log("elimino: ", id);

    db.collection("percorsi/")
      .doc(id)
      .delete()
      .then(function () {
        console.log("Document successfully deleted!");
      })
      .catch(function (error) {
        console.error("Error removing document: ", error);
      });
  }

  resetForm() {
    console.log("reset form");
    let percorso = {
      nome: "",
      lunghezza: "",
      utente: "",
    };
    this.setState({ id: "", percorso: percorso });
  }

  render() {
    return (
      <Container>
        <header className="App-header">
          <AggiungiPercorso
            id={this.state.id}
            percorso={this.state.percorso}
            onHandleChange={(e) => this.handleChange(e)}
            onHandleSubmit={(e, id) => this.handleSubmit(e, id)}
            onResetForm={(e) => this.resetForm(e)}
          ></AggiungiPercorso>
        </header>
        <div>
          <ListaPercorsi
            onEliminaPercorso={(id) => this.eliminaPercorso(id)}
            onModificaPercorso={(id) => this.modificaPercorso(id)}
          ></ListaPercorsi>
        </div>
      </Container>
    );
  }
}

export default Percorsi;
