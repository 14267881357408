import React, { Component } from "react";
import { Button, Container, Alert, Spinner, Col, Row, Card } from "react-bootstrap";
import { auth, provider } from "../services/firestore";

class Login extends Component {
  constructor(props) {
    super(props);
    this.email = React.createRef();
    this.password = React.createRef();
    this.phone = React.createRef();
  }

  state = {
    error: "",
    //loading: true,
  };

  handleLogin(e) {
    e.preventDefault();
    let email = this.email.current.value;
    let password = this.password.current.value;

    auth
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        console.log("Loggato", res);
        this.props.autenticato = true;
      })
      .catch((error) => {
        this.setState({ error: error.message });
        console.log(error);
      });
  }

  /*
  loginPhone(e) {
    auth
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(function (confirmationResult) {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
      })
      .catch(function (error) {
        // Error; SMS not sent
        // ...
      });
  }
*/
  loginGoogle(e) {
    provider.setCustomParameters({
      hd: "unitbit.com",
    });
    this.setState({ loading: false });

    //imposto ora inizio login prima del redirect
    localStorage.setItem("googlelogin", new Date().getTime() / 1000);

    auth.signInWithPopup(provider).then((res) => {
      this.setState({ loading: false });
    });
  }

  /*
  componentDidMount() {
    let now = new Date().getTime() / 1000;
    let elapsed = now - localStorage.getItem("googlelogin");
    console.log(localStorage.getItem("googlelogin"));
    console.log("el", elapsed);

    //lascio all'utente 100 secondi per fare il login
    if (localStorage.getItem("googlelogin") != null && elapsed < 20) {
      this.setState({ loading: true });
    }
    //se non va a buon fine annullo login
    else {
      this.setState({ loading: false, error: "Timeout del Login (60s)" });
      localStorage.removeItem("googlelogin");
    }
  }
  */

  render() {
    return this.props.loading ? (
      <Container>
        <Spinner
          style={{
            display: "block",
            position: "fixed",
            top: "50%",
            right: "50%",
          }}
          animation="border"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </Spinner>
      </Container>
    ) : (
      <Container>
        {this.state.error ? (
          <Alert variant="danger">{this.state.error}</Alert>
        ) : (
          ""
        )}
        <Row>
          <Col></Col>
          <Col md={4} xs={12}>
            <Card style={{ marginTop : '50%' }}>
            <Card.Img variant="top" src="https://images.creativemarket.com/0.1.0/ps/8606756/1820/1214/m1/fpnw/wm0/bike-icon-ride-bicycle-concept-travel-and-tourism-mountain-cycle-thin-line-web-symbol-on-white-background-.jpg?1593031433&s=56618e710a345f5fb22d1b80cffc7713" />
            <Card.Body>
              <Card.Title><h3 style={{textAlign:'center'}}>UnitBike</h3></Card.Title>
                <Button
                  size="lg"
                  block
                  variant="danger"
                  onClick={(e) => this.loginGoogle(e)}
                >
                  Login con Google
                </Button>
            </Card.Body>
          </Card>
        </Col>
        <Col></Col>
        </Row>
      </Container>
    );
  }
}

export default Login;
