import firebase from "firebase";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyBeh_cMtHpx8NF0QZeGZjxfYb-prHQpngE",
  authDomain: "unitbike-3a7f2.firebaseapp.com",
  databaseURL: "https://unitbike-3a7f2.firebaseio.com",
  //databaseURL: "http://localhost:8080",
  projectId: "unitbike-3a7f2",
  storageBucket: "unitbike-3a7f2.appspot.com",
  messagingSenderId: "342375061099",
  appId: "1:342375061099:web:75abd7c7c6c66408d9c32e",
};
firebase.initializeApp(firebaseConfig);

//console.log("io");
var db = firebase.firestore();
if (window.location.hostname === "localhost") {
  db.useEmulator("localhost", 8080);
}

export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const fb = firebase;
export {db};
